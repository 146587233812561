@import 'style/_mixin.sass'

html,
body
  position: relative
  background: #fff

body
  display: block
  position: relative
  min-width: 320px
  min-height: 100vh
  line-height: 1.25
  font-family: 'Muli', serif
  font-size: (18/16) * 1em
  font-weight: normal
  color: $color-dark-0
  background-color: #fcfcfc
  overflow-x: hidden
  overflow-y: auto
  z-index: 0

*,
*:after,
*:before
  box-sizing: border-box

*:focus
  outline: 0

button
  padding: 0
  margin: 0
  background-color: transparent
  font: inherit
  border: 0

a
  text-decoration: none

.grecaptcha-badge
  visibility: hidden

input
  display: inline-block
  background: none
  color: inherit
  font: inherit
  border: none
  outline: 0

  &[disabled],
  &[readonly]
    cursor: default

  &:-webkit-autofill
    -webkit-box-shadow: 0 0 0 1000px #fff inset

  &[type=number]
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button
      -webkit-appearance: none
      margin: 0

  &::-ms-clear
    display: none
    width: 0
    height: 0

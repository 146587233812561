@import 'style/_mixin.sass'

$width: 30px
$height: 2px
$spacing: 6px
$color: #000

.root
  position: relative
  display: block
  padding: 0
  border: 0
  background: transparent
  cursor: pointer

.content
  display: block
  top: 50%
  left: 50%
  width: $width
  height: $height * 3 + $spacing * 2
  margin-top: $height / -2
  transform: translateX(-50%)

  &,
  &::before,
  &::after
    position: absolute
    height: $height
    background-color: inherit
    @include border-radius

  &::before
    $ratio: 0.60
    content: ""
    display: block
    right: 0
    left: auto
    width: $width * $ratio
    transition: top 0.25s ease-in-out, bottom 0.25s ease-in-out, opacity 0.25s ease-in-out

  &::after
    $ratio: 0.80
    content: ""
    display: block
    right: 0
    left: auto
    width: $width * $ratio
    transition: top 0.25s ease-in-out, bottom 0.25s ease-in-out, opacity 0.25s ease-in-out

  &::before
    top: ($spacing + $height) * -1

  &::after
    bottom: ($spacing + $height) * -1

.active
  .content
    &::before,
    &::after
      opacity: 0

    &::before
      top: 0

    &::after
      bottom: 0

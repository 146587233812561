@import 'style/_mixin.sass'

.root
  padding: 80px 0 40px
  background-color: $color-primary
  text-align: center

.copyright
  text-align: center

@include media-wider-than(sm)
  .root
    text-align: left

  .navigation
    display: flex
    justify-content: space-between

@import 'style/_mixin.sass'

.header
  position: relative
  height: $header-height-xs

.root
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: $header-height-xs
  background-color: #fcfcfc
  z-index: $header-z-index

.content
  position: relative

.logo,
.toggle
  position: absolute
  top: $header-height-xs / 2
  transform: translateY(-50%)
  z-index: 1

.logo
  left: 0
  display: inline-block
  height: $header-height-xs / 2
  background-color: $color-light
  vertical-align: middle

.toggle
  right: 0
  width: $header-height-xs
  height: $header-height-xs
  background-color: $color-dark

.toggleContent
  background-color: $color-light

.item
  padding: 10px 0
  +fade-out
  ///R
  transform: translateX(20px)
  transition: transition(opacity), transition(visibility), transition(transform)
  transition-duration: 0
  transition-delay: 0

.link
  position: relative
  display: inline-block
  padding: 10px
  font-size: 2em
  font-weight: bold
  vertical-align: middle
  cursor: pointer
  overflow: hidden

  &:before
    content: ''
    position: absolute
    left: 0
    right: 20%
    bottom: 0.25em
    height: 0.5em
    @include border-radius
    ///R
    background-color: $color-accent
    transform: translateX(-150%)
    transition: transition(transform)
    z-index: -1

.navigation
  +stretch(fixed)
  top: $header-height-xs
  display: flex
  align-items: center
  background-color: #fcfcfc
  text-align: center
  @include fadeable
  @include scrollable

.navigationContent
  width: 100%

.navigationContainer
  min-width: 0

.open
  .navigation
    @include fade-in

  .item
    +fade-in
    ///R
    transform: translateX(0)

    &:nth-child(4n+2)
      transition-delay: 0.15s

    &:nth-child(4n+3)
      transition-delay: 0.30s

    &:nth-child(4n+4)
      transition-delay: 0.45s

  .link
    &.active
      &:before
        transform: translateX(0)
        transition-duration: 1s
        transition-delay: 0.1s

.top
  margin: 0 -10px

@include media-wider-than(sm)
  .header,
  .root
    height: $header-height-sm

  .logo,
  .toggle,
  .navigation
    top: $header-height-sm / 2

  .logo
    height: $header-height-sm / 1.75

  .toggle
    display: none

  .navigation
    position: absolute
    left: auto
    right: 0
    bottom: auto
    padding-top: 0
    @include fade-in
    ///R
    background: transparent
    overflow: hidden
    transform: translateY(-50%)

  .navigationContainer
    padding: 0

  .item
    padding: 0
    +fade-in
    ///R
    transform: none
    transition: none

    &:not(:first-child)
      margin-left: 20px

  .top
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    align-items: center
    margin: 0

  .link
    font-size: 1.25em

    &:before
      bottom: 0.5em

    &.active
      &:before
        transform: translateX(0)
        transition-duration: 0.5s
        transition-delay: 0

    +hover-or-focus
      &:before
        transform: translateX(0)
        transition-duration: 0.5s
        transition-delay: 0

@include media-wider-than(lg)
  .root
    height: 0

  .item
    &:not(:first-child)
      margin-left: 40px
